<template>
  <div>
    <portal to="page-top-title">List #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'contactLists-edit', params: { id: id } }" icon="fa-edit" text v-if="list.metadata.editable.this">Edit</btn>
    </portal>

    <box-single :url="`/contact-lists/${id}`" data-prop="list" resp-prop="data" loader  @request-after="requestAfter">
      <template slot="content">
        <row>
          <column-info title="Name">{{ list.name }}</column-info>
          <column-info title="Category">
            <router-link :to="{ name: 'contactLists-categories-single', params: { id: list.category.id } }">{{ list.category.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ list.created_at }}</column-info>
          <column-info title="Updated At">{{ list.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Price">{{ priceFormatter.format(list.price) }}</column-info>
          <column-info title="Custom ID">
            <code>{{ list.custom_id }}</code>
          </column-info>
          <column-info :md="6" title="Endpoint" v-if="isLive">
            <nullable :value="list.endpoint">
              <code slot="value">{{ list.endpoint }}</code>
            </nullable>
          </column-info>
          <column-info :md="6" title="File" v-else>
            <nullable :value="file.original_name" text="Lost"/>
          </column-info>
        </row>
        <row>
          <column-info title="Active">
            <badge-active :value="list.active"/>
          </column-info>
          <column-info title="Live">
            <badge-active :value="list.live_list"/>
          </column-info>
          <column-info title="Status">
            <badge-status :value="list.status_text"/>
          </column-info>
          <column-info title="Rows Parsed" v-if="!isLive">
            <badge>{{ file.rows_parsed || 0 }}</badge>
          </column-info>
        </row>
        <row>
          <column-info title="Contacts">
            <badge title="Total">{{ list.contacts_count_total }}</badge>
            <badge title="Active" color="green">{{ list.contacts_count_active }}</badge>
            <badge title="Inactive" color="red">{{ list.contacts_count_inactive }}</badge>
          </column-info>
          <column-info title="Messages">
            <stats-messages :stats="contactListStats"/>
          </column-info>
          <column-info title="Clicks">
            <stats-clicks :stats="contactListStats"/>
          </column-info>
          <column-info title="Revenues">
            <stats-revenues :stats="contactListStats"/>
          </column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Contacts" :url="`/contact-lists/${id}/contacts`" data-prop="contacts" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th class="text-center">Active</th>
          <th class="text-center">Opener</th>
          <th class="text-center">Clicker</th>
          <th class="text-center">Blacklisted</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="contact in contacts" :key="`contacts-tr-${contact.id}`" :value="contact">
          <td>{{ contact.id }}</td>
          <td>{{ contact.email_string }}</td>
          <td>{{ contact.first_name }}</td>
          <td>{{ contact.last_name }}</td>
          <td class="text-center">
            <badge-active :value="contact.active"/>
          </td>
          <td class="text-center">
            <badge-active :value="contact.email.opener"/>
          </td>
          <td class="text-center">
            <badge-active :value="contact.email.clicker"/>
          </td>
          <td class="text-center">
            <badge-active :value="contact.email.blacklisted"/>
          </td>
          <td class="text-right">{{ contact.created_at }}</td>
          <td class="text-right">{{ contact.updated_at }}</td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import Nullable from '@/views/components/simple/Nullable'
import BoxTable from '@/views/components/data/BoxTable'
import StatsMessages from '@/views/components/stats/Messages'
import StatsClicks from '@/views/components/stats/Clicks'
import StatsRevenues from '@/views/components/stats/Revenues'
import contactList from '@/modules/strubs/contactList'

export default {
  name: 'ContactListsSingle',
  metaInfo () {
    return { title: 'List #' + this.id }
  },
  components: {
    BoxSingle,
    Btn,
    Badge,
    BadgeActive,
    BadgeStatus,
    Nullable,
    BoxTable,
    StatsRevenues,
    StatsClicks,
    StatsMessages
  },
  computed: {
    id () { return this.$route.params.id },
    isLive () { return this.list.live_list },
    file () { return this.list.file ? this.list.file : {} }
  },
  data () {
    return {
      list: contactList,
      contactListStats: {},
      priceFormatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }),
      contacts: []
    }
  },
  methods: {
    requestAfter () {
      this.$http
        .get('/contact-lists/stats', { params: { ids: [this.id] } })
        .then((res) => {
          const { data: { data } } = res
          const id = Number(this.id)

          this.contactListStats = data.find((stats) => stats.id === id)
        })
    }
  }
}
</script>
